$(document).ready(function($) {
/*
  $( ".addtocart" ).click(function() {
    alert( "Handler for .click() called." );
  });
  /*
  $('#addtocart').click(function(e) {
          e.preventDefault();
          var $this = $(this);
          alert($(this).data('id'));
          $.ajax({
              type: 'POST',
              contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
              url: '/api/v1/add_to_cart',
              data: {
                  product_id: $(this).data('id'),
                  product_amount: 1,
              },
              dataType: 'json'
          }).success(function(data) {
              if (typeof data.success != 'undefined' && data.success == 1) {
                  window.location.href('/cart');
                  //window.refreshCart();
                  //toastr.success('Tuote lisätty ostoskoriin!')
              } else {
                  alert("Virhe lisättäessä tuotetta. Yritä myöhemmin uudelleen");
              }
          }).fail(function(data) {
              alert("Virhe lisättäessä tuotetta. Yritä myöhemmin uudelleen");
          }).always(function() {
              $this.removeClass("disabled");
          });
      });
*/

  $( ".add_one" ).click(function() {
    var item_id = $(this).data("item-id");

    $.ajax({
      method: "POST",
      url: "/api/v1/inc_cart_item",
      data: { item_id: item_id }
    })
    .done(function( msg ) {
      $('#item_amount_' + item_id).text(msg.amount + " kpl");
      $('#total_price').text(msg.total_price);
      $('#line_price_' + item_id).text(msg.line_price);
      $('#shipping_price').text(msg.shipping_price);
      $('#total_weight').text(msg.total_weight);
    });
  });

  $( ".dec_one" ).click(function() {
    var item_id = $(this).data("item-id");

    $.ajax({
      method: "POST",
      url: "/api/v1/dec_cart_item",
      data: { item_id: item_id }
    })
    .done(function( msg ) {
      if (msg.deleted == true) {
        location.reload();
      } else {
        $('#item_amount_' + item_id).text(msg.amount + " kpl");
        $('#line_price_' + item_id).text(msg.line_price);
        $('#total_price').text(msg.total_price);
        $('#shipping_price').text(msg.shipping_price);
        $('#total_weight').text(msg.total_weight);
      }
    });
  });


  var mySwiper = new Swiper ('.swiper-container', {
        loop: true,
        pagination: '.swiper-pagination',
        paginationClickable: true,
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev'
    });
    
});
